.generador{
  @media screen and (max-width: 750px) {
    .help_over{
      position: fixed !important;
      overflow: hidden;
      display: none;
      top: 80%;
      transform: translate(-50%, -50%);
      
      img{
        width: 60vw !important;
      }
        
      
    }
  }
  @media (max-width:1300px) {
    .inputs_generador, .opciones_generador {
      width: 100% !important;
    }
    .div_todos_tamanios{
      justify-content: center;
    }
    .div_todas_etiquetas{
      display: flex;
      justify-content: center;
    }
    .btn_generador{
      margin-left: 50% !important;
      transform: translateX(-50%);
    }
  }
  .separar {
    display: block;
  }
  
  .sin_borde {
    border: none !important;
  } 
  .form_generador { 
    margin: 26pt auto;
    text-align: left;
  }
  .label_generador {
    margin-left: 12pt;
    display: block;
  }
  .inputs_generador { 
    width: 59%; 
    padding-left: 6pt;
    text-align: left;
    border: 1pt solid #c3c1c1;
    border-radius: 5pt;
    margin-top: 2pt;
    display: block;
    font-size: 10pt;
    height: 20pt;
  }
  .margen_inputs_generador {
    margin-top: 5pt;
  }
  .form_generador p {
    margin-top: 13pt;
    margin-bottom: 30pt;
    font-weight: bold;
    font-size: 10pt;
  }
  .opciones_generador {
    width: 59%; 
    border-radius: 5pt;
  }
  .parrafo_caracteristicas {
    position: relative;
  }
  .simbolo_interrogacion {
    border-radius: 50%;
    background-color: #63A830;
    width: 13pt;
    height: 13pt;
    color: #FFF;
    display: inline-flex;
    justify-content: center;
    justify-items: center;
    font-size: 8pt;
    margin-left: 4pt;
  }
  .div_todas_etiquetas {
    margin-top: 26pt;
    width: 100%;
    height: 107pt;
  }
  .div_todos_tamanios  { 
    width: 100%;
    height: 101pt;
  }
  .div_sin_etiquetas {
    width: 60pt;
    height: 80pt;
    border: 4pt solid #63A830;
    color: #63A830;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    float: left;
    margin-left: 15pt;
  }
  .div_con_etiquetas {
    border: 1pt solid #c3c1c1;
    border-radius: 5pt;
    width: 60pt;
    height: 80pt;   
    float: left;
    margin-left: 15pt;
    position: relative;
    margin-right: 10pt;
  }
  .div_todos_tamanios {
    display: flex; 
    align-items: center;
    position: relative;
  }
  .div_tamanio_a4 {
    width: 110pt;
    height: 80pt;
    border: 1pt solid #63A830;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    margin-left: 15pt; 
  }
  .div_interno_a4 {
    width: 100pt;
    height: 70pt;
    background-color: #63A830; 
  }
  .div_tamanio_a5 {
    width: 80pt;
    height: 110pt;
    border: 1pt solid #63A830;  
    float: left;
    margin-left: 45pt;
    display: flex;
    justify-content: center;
  } 
 .simbolo_x:hover, .div_sin_etiquetas:hover, .div_a4:hover, .div_a5:hover, .btn_generador:hover {
    opacity: 0.4;
    cursor: pointer;
  }
  .div_interno_a5 {
    width: 70pt;
    height: 50pt;
    background-color: #63A830; 
    margin-top: 5pt;
  }
  .div_span_tamanios {
    width: 100%;
    height: 18pt;
    margin-top: 20pt;
  }
  .span_tamanio_a4 { 
    position: absolute;
    top: 127pt;
    left: 62pt;
  }
  .span_tamanio_a5 { 
    position: absolute;
    top: 127pt;
    left: 202pt;
  }
  .span_tamanio_a4, .span_tamanio_a5 {
    color: #63A830;   
    font-weight: 600;
    font-size: 14pt;
  }
  .div_sin_etiquetas .simbolo_mas {
    font-size: 35pt;
    margin: 0;
  }
  .span_tamanio_a4, .span_tamanio_a5 {
    clear: left;
  }
  .mostrar {
    display: flex;
    align-items: center;
    padding: 2pt;
  }
  .imagen_etiqueta {
    width: 100%;
  }
  .simbolo_x {
    position: absolute;
    right: -15pt;
    top: -1pt;
    border-radius: 50%;
    background-color: #63A830;
    width: 13pt;
    height: 13pt;
    color: #FFF;
    display: flex;
    justify-content: center;
    justify-items: center;
    font-size: 8pt;
  }
  .esconder {
    display: none;
  }
  .parrafo_generador {
    margin-top: 68pt !important;
  }
  .sin-margin {
    margin-top: 13pt !important;
  }
  
  .btn_generador {  
    background-color: #63A830;
    color: #FFF;
    display: block;
    margin-left: 74pt;
    width: 190pt;
    text-transform: uppercase;
    padding-top: 10pt;
    padding-bottom: 10pt;
    border: 0pt solid #c3c1c1;
    border-radius: 5pt;
    font-weight: 700;
    margin-top: 18pt;
  }
  .formato_seleccionado {
    opacity: 0.4;
    cursor: none;
  }
  .help_over{
    position: absolute;
    margin-left: 140pt;
    margin-top: -100pt;
    padding: 1pt;
    background-color: white;
    border-radius: 5pt;
    box-shadow: 0 0 10pt rgba($color: #000000, $alpha: 0.2);
    overflow: hidden;
    display: none;
    z-index: 10;

    img{
      width: 250pt;
      
    }
  }
  .simbolo_interrogacion{
    cursor: pointer;
  }
  .simbolo_interrogacion:hover{
    background-color: #63A8308f;

    .help_over{
      display: block;
     
    }

  }
  
  p{
    clear: both;
  }
  /* Estilo para la etiqueta "Producto Peligroso" */
  label.checkbox-label {
    display: flex;
    align-items: center;
    margin-top: 27px;
    margin-bottom: 18pt;
    margin-left: 12pt;
    font-size: 13px;
    color: #333;
    font-weight: bold;  
  } 
  /* Estilo para el checkbox en sí */
  input[type="checkbox"] {
    margin-right: 6px;  
    margin-left: 4pt;
    width: 16px;  
    height: 16px; 
    appearance: none;  
    border: 2px solid #63A830;  
    border-radius: 4px;  
    cursor: pointer; 
  } 
  /* Estilo para el checkbox cuando está marcado */
  input[type="checkbox"]:checked {
    background-color: #63A830;  
    border-color: #63A830;  
  } 
  /* Estilo para el texto junto al checkbox cuando está marcado */
  input[type="checkbox"]:checked + label.checkbox-label {
    color: #63A830;  
    font-weight: bold; 
  }

  
}