.banner_info {
	width: 12%;
    min-width: 150pt;
    position: absolute;
    margin-top: 190pt;
    left: 8vw;
}

@media screen and (max-width: 1000px) and (min-width: 771px) {
    .banner_info{
        left: 3vw;
    }
}

@media screen and (max-width: 770px) {
    .banner_info{
        display: none;
    }
}