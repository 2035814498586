
.div_principal_etiquetas {   
  margin-top: 25pt;
  margin-bottom: 10pt; 
}
.titulo_etiquetas {  
  font-size: 19pt;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 29pt;
} 
.div_principal_etiquetas ul {
  list-style: none;
  margin-left: -24pt;
} 

.pagination{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.spacer{
  display: inline-block;
  width: 60%;
}