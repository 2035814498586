.kcs-player-screen {
	position: fixed;
	height: fit-content;
	width: 80vw;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 90;
}

.kcs-player-screen-overlay {
	position: fixed;
	display: block;
	z-index: 89;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.3);
}

.kcs-player-close {
	position: fixed;
	top: 10pt;
	right: 10pt;
	font-size: 1.5rem;
	color: #fff;
	transition: all .5s;
	cursor: pointer;
	z-index: 91;
}

.kcs-player-close:hover {
	color: #ff4444;
}

@media screen and (max-width: 1000px) and (min-width: 771px) {
	.kcs-player-image{
			left: 3vw !important;
	}
}

@media screen and (max-width: 767px) {
	.kcs-player-image{
		position: relative !important;
		margin: 10pt auto;
		width: 80% !important;
		left: 0 !important;
	}
}
.kcs-player-image {
	position: absolute;
	margin-top: 50pt;
	width: 12%;
	min-width: 150pt;
	left: 8vw;
	color: white;
	background-color: #63A830;
	border-radius: 4pt;
	padding: 9pt 5pt;
	cursor: pointer;
}

.kcs-player-image:hover{
	opacity: .8;
}

.kcs-palyer-image-tag{
	width: 80%;
	margin: 4pt 0;
}