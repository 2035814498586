@media screen and (max-width: 700px) {
  .formulario_resetear{
    width: 80% !important;
  }
}
.formulario_resetear {
  width: 36%; 
  margin: 35pt auto;
  text-align: center;
}
.titulo_resetear {
  font-size: 26pt;
  font-weight: 700;
}
.p_resetear {
  font-size: 12pt;
  margin-left: auto;
  margin-right: auto; 
  margin-top: 19pt;
  margin-bottom: 29pt;
}
.imagen_resetear {
  width: 12%;
}
.display_inputs_resetear {
  display: block;
}
.inputs_resetear { 
  margin-top: 10pt;
  width: 82%;
  padding-top: 6pt;
  padding-bottom: 6pt;
  text-align: center; 
  border: 1pt solid #c3c1c1;
  border-radius: 5pt;
  font-size: 11pt;
}  
.btn_form_resetear {  
  background-color: #63A830;
  color: #FFF; 
  margin-bottom: 18pt;
  margin-top: 18pt;
  margin-left: auto;
  margin-right: auto;
  width: 82%;
  height: 36pt;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0pt solid #c3c1c1;
  border-radius: 5pt;
  font-weight: 500;
  font-size: 13pt;
} 
.parrafo_error_resetear {
  font-size: 11pt;
  margin: 6pt auto;
}
.div_esconder_error_resetear {
  display: none; 
}
.div_mostrar_error_resetear {
  display: block;
  color: red;
  border: 1pt solid;
  border-radius: 5pt;
  margin: 10pt auto;
  width: 82%;
}