@media screen and (max-width: 700px) {
  .form_registro {
    width: 80% !important;
  }

  .sin_borde_registro {
    display: block;
  }
}

.form_registro {
  width: 42%;
  margin: 26pt auto;
  text-align: left;
  min-width: 200pt;
}

.label_registro {
  color: #404040;
  font-size: 10pt;
  display: block;
}

.margen_registro {
  margin-top: 33pt;
}

.sin_borde_registro {
  border: none !important;
  margin: 2pt;
  margin-left: 7pt;
  height: 20pt;
  font-weight: bold;
  font-size: 10pt;
  width: 60%;
}

.checkbox_form_registro {
  margin-top: 22pt;
  margin-bottom: 27pt;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10pt;
}

.checkbox_form_registro label {
  margin-left: 5pt !important;
}

.parrafo_error_registro {
  font-size: 8pt;
  margin: 6pt auto;
  text-align: center;
}

.div_esconder_error_registro {
  display: none;
}

.div_mostrar_error_registro {
  display: block;
  color: red;
  border: 1pt solid;
  border-radius: 5pt;
  margin: 10pt auto;
  width: 82%;
}

.btn_form_registro {
  background-color: #63A830;
  color: #FFF;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 53%;
  height: 36pt;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0pt solid #c3c1c1;
  border-radius: 5pt;
  font-weight: 500;
}

.btn_form_registro:hover {
  opacity: 0.4;
}