.div_listado_etiquetas {
  display: flex;
  align-items: center; 
}
.imagen_etiquetas { 
  margin-right: 28pt;
  max-width: 80pt;
} 
.div_parrafos_etiquetas p {
  margin-bottom: 0;
}
.parrafo_c_ler, .parrafo_residuo {
  text-transform: uppercase;
  font-size: 7pt;
}
.parrafo_c_ler span, .parrafo_residuo span {
  margin-left: 5pt;
  font-size: 11pt;
}
.parrafo_detalles {
  font-size: 11pt;
}
.div_iconos {
  margin-left: 21pt;
  margin-top: 54pt;
}
.flecha_etiquetas {
  width: 21pt;
  height: 21pt;
}
.cerrar_etiquetas {
  width: 23pt;
  height: 23pt;
  margin-left: 7pt;
}
hr {   
  border-top: 2pt solid #63A830;
  margin-top: 28pt;
  margin-bottom: 28pt;
}

.iconos-btn{
  margin: 0 3pt;
  display: inline-block;
}

.flecha_etiquetas:hover, .editar_etiquetas:hover, .cerrar_etiquetas:hover {
  cursor: pointer;
  opacity: 0.4;
}
.hide {
  display: none;
}

.editar_etiquetas{
  width: 15pt;
  margin-right: 3pt;
  height: 15pt;
  position: relative;
  
}
.editar_etiquetas::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}