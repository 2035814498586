@media screen and (max-width: 750px) {
  .div_principal_menu {
    margin-top: 30pt;
    background-color: #63A830; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .wrapper{
    width: 85% !important;
    min-width: 200pt;
    margin: 1pt auto;
  }
  .sub_div_menu_b,.sub_div_menu_v { 
    width: 84vw !important;
    height: 40pt !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5pt;
    border: 1pt solid #FFF;
    cursor: pointer;
  }
  .imagen_menu {
    width: 20pt !important;
  } 
}
.div_principal_menu {
  margin-top: 30pt;
  background-color: #63A830; 
  display: flex;
  width: fit-content;
  justify-content: center;
}
a{
  text-decoration: none;
}
.sub_div_menu_b, .sub_div_menu_v { 
  width: 105pt;
  height: 60pt;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5pt;
  border: 1pt solid #FFF;
  cursor: pointer;
}
.sub_div_menu_b {
  background-color: #FFF;
  color: #63A830;
}
.sub_div_menu_v {
  background-color: #63A830;
  color: #FFF;
}
.imagen_menu {
  width: 27%;
} 
.p_menu {
  text-transform: uppercase;
  margin-left: 8pt;
  font-size: 10pt;
  margin-bottom: 0pt;
  width: 42%;
}

.sub_div_menu:hover {
  background-color: #FFF;
  color: #63A830;
}