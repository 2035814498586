.wrapper{
  width: 40%;
  min-width: 200pt;
  margin: 1pt auto;
}
@media screen and (max-width: 750px) {
  .wrapper{
    width: 85% !important;
    min-width: 200pt;
    margin: 1pt auto;
  }
}
.misDatos{
  .form_mis_datos {
    margin: 26pt auto;
    text-align: left;
    min-width: 200pt;
  }
 
  .form_mis_datos label {
    color: #4b4a4a;  
    margin-top: 7pt;
    font-size: 10pt; 
  }
  .form_mis_datos h2 {
    text-transform: uppercase; 
    margin-bottom: 27pt;
    font-weight: 500;
    font-size: 20pt;
  }
  .separar_mis_datos {
    display: block;
  }
  .margen_contra {
    margin-top: 37pt !important;
  }
  .sin_borde_mis_datos {
    border: none !important;
    margin-left: 7pt;
    font-weight: bold;
    font-size: 11pt;
    width: 50%;
    height: 20pt;
  }
  .div_subir_imagen_mis_datos {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 20pt; 
  }
  .imagen_cargada_mis_datos { 
    width: 11%; 
  }
  .cargar_imagen_mis_datos { 
    margin-left: 10pt;
    display: flex; 
    align-items: center;
    cursor: pointer;
  }
  .cargar_imagen_mis_datos *{ 
    cursor: pointer;
  }
  .cargar_imagen_mis_datos:hover, .btn_form_mis_datos:hover, .simbolo_x:hover {
    cursor: pointer;
    opacity: 0.4;
  }
  .subir_imagen_mis_datos {
    width: 20pt;
    height: 20pt;
    margin-right: 5pt;
  }
  .label_imagen_mis_datos {
    font-weight: bold;
    font-size: 10pt;
    margin-bottom: 0pt;
  }
  .simbolo_x {
    position: absolute;
    left: 62pt;
    top: -12pt;
    border-radius: 50%;
    background-color: #63A830;
    width: 13pt;
    height: 13pt;
    color: #FFF;
    display: flex;
    justify-content: center;
    justify-items: center;
    font-size: 8pt;
  }
  .esconder {
    display: none;
  }
  .btn_form_mis_datos {  
    background-color: #63A830;
    color: #FFF;
    display: block; 
    width: 39%;
    height: 36pt;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20pt; 
    border: 0pt solid #c3c1c1;
    border-radius: 5pt;
    font-weight: 700;
  }  
  .error{
    color: red;
  }
  .ul_error{
    li{
      margin-left: 1ch;
      &::before{
        content: "- ";
      }
    }
  }
}