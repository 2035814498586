.listItem_Simbolos{
	display: flex;
	cursor: pointer;
	width: 100%;
	border-bottom: 2pt solid #63A830;
	padding: 5pt;
	align-items: center;
	transition: all .3s;
}
.listItem_Simbolos:hover{
	border-bottom: 3pt solid #63A830;
	box-shadow: 2pt 6pt 20px rgba(0, 0, 0, .1);
}
.listItem_Simbolos_code{
	text-align: center;
	width: 50%;
}
.listItem_Simbolos_code img{
	height: 80pt;
	width: initial;
}
