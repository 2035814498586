.peligrosoSelector-wrapper{
    margin-top: 30pt;
    width: 65%;
}
@media (max-width: 1300px){
    .peligrosoSelector-wrapper{
        width: 100% !important;
    }
}
.peligrosoSelector-wrapper .selector{
    display: flex;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    width: 100%;

}
.peligroso, .no-peligroso{
    display: block;
    padding:19pt 14pt;
    text-align: left;
    margin: 2pt 20pt;
    position: relative;
    z-index: 6;
    text-transform: uppercase;
    cursor: pointer;
    transition: all .3s;
    width: 60%;
}

.peligroso:hover, .no-peligroso:hover{
    color: rgba(0,0,0,.9);
}
.selector-title{
    width: 100%;
    margin-bottom: 20pt;
    text-align: center;
}
.peligroso::before, .no-peligroso::before{
    content: "";
    border: 1pt solid black;
    display: block;
    position: absolute;
    top: 50%;
    left: 0%;
    width: 20pt;
    border-radius: 20pt;
    height: 20pt;
    transform: translate(-50%, -50%);
}

.peligroso:hover::after, .no-peligroso:hover::after{
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0%;
    border-radius: 20pt;
    width: 15pt;
    height: 15pt;
    background: black;
    z-index: -1;
    transform: translate(-50%, -50%);
}