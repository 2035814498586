.kcs-list-wrapper{
    max-height: 300pt;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 4pt;
    border: solid 1pt #c3c1c1;
    border-radius: 4pt;
    scrollbar-color: #63A830 transparent;
    scrollbar-width: thin;
    width: 59%
}
.kcs-list-wrapper::-webkit-scrollbar{
    background-color: transparent;
    width: 9pt;
}
.kcs-list-wrapper::-webkit-scrollbar-thumb{
    background: #63A830;
    border-radius: 7pt;
}


.kcs-list-title{
    font-weight: bold;
    color: #63A830;
    transition: all .4s;
}

.kcs-list-subtitle{
    font-weight: bold;
    margin-left: 1.4rem;
    color: #467724;
    transition: all .4s;
}

.kcs-list-item{
    margin-left: 3.4rem;
    display: block;
    padding: 10pt 7pt;
    cursor: pointer;
    border-radius: 3pt;
    /* overflow: hidden; */
    transition: all .4s;
    position: relative;
}

.kcs-list-item.active{
    color: #63A830;
    /* background: #63A830; */
    position: relative;
}
.kcs-list-item.active::before{
    content: "";
    background: #467724;
    position: absolute;
    left: -25pt;
    display: block;
    width: 20pt;
    height: 20pt;
    border-radius: 20pt;
    top: 50%;
    transform: translateY(-50%);
}

.kcs-list-item:hover{
    color: #63A830;
}

.kcs-list-item:hover::after{
    content: "";
    animation: selectionAnimation 1s 1 forwards;
    position: absolute;
    left: 0;
    transform: translateY(-50%);
        top: 50%;
    height: 100%;
    background: rgba(99, 168, 48,1);
    z-index: 0;
    
}

@keyframes selectionAnimation{
    0%{
        width:0;
        left: 40vw;
    }
    30%{
        width: 100%;
        left: 0vw;
        
    }
    50%{
        width: 100%;
        left: -20pt;
    }
    
    80%{
        width: 20pt;
        left: -20pt;
        top: 50%;
        height: 100%;
        border-radius: 0pt;

    }
    100%{
        width: 20pt;
        left: -20pt;
        height: 20pt;
        top: 50%;
        border-radius: 20pt;
        /* clip-path: polygon(0 0 , 100% 50%, 0% 100%); */
    }
}