.div_menu_principal {
	display: block;
    width: 100%; 
    background-color: #e6e6e6;
}
.col_l_m_p, .col_p_m_p, .span_logo_salir {
    display: flex;
    justify-content: center;
}
.div_logo_m_p, .div_logout_m_p {
    margin-top: 20pt;  
}
.logo_menu_principal { 
	width: 69%; 
    margin-right: auto;
    margin-left: auto;
    display: block; 
}
.col_p_m_p { 
    align-items: end;
}
.parrafo_menu_principal {
    text-transform: uppercase;
    color: #006837;
    font-weight: 700;
}
.div_p_m_principal hr {
    border-top: 4pt solid #62a830;
    margin-top: 4pt;
    margin-bottom: 10pt;
} 
.logout_menu_principal {
    width: 9%;
}
.span_logo_salir {
    cursor: pointer;  
    align-items: center;
} 
.logo_menu_principal:hover, .span_logo_salir:hover {
    opacity: 0.6;
} 
.esconder {
    display: none;
  }