@media screen and (max-width: 700px) {
  .inicio_formulario_login{
    width: 80% !important;
  }
}
.inicio_formulario_login {
  width: 36%; 
  margin: 35pt auto;
  text-align: center;
}
.titulo_login {
  font-size: 26pt;
  font-weight: 700;
}
.p_login {
  font-size: 12pt;
  margin-left: auto;
  margin-right: auto; 
  margin-top: 19pt;
  margin-bottom: 29pt;
}
.imagen_login {
  width: 12%;
}
.sub_login {
  font-size: 15pt;
  color: #63A830;
  font-weight: 900;
  text-transform: uppercase;
  margin-top: 10pt; 
}
.display_inputs_login {
  display: block;
}
.inputs_login { 
  margin-top: 10pt;
  width: 82%;
  padding-top: 6pt;
  padding-bottom: 6pt;
  text-align: center; 
  border: 1pt solid #c3c1c1;
  border-radius: 5pt;
  font-size: 11pt;
}
.olvidar_contra_login {
  margin-top: 9pt;
  font-size: 11pt;
}
.checkbox_login {
  margin-top: 20pt;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input_checkbox_login {
  margin-right: 5pt;
}
.label_checkbox_login {
  
}
.btn_form_login {  
  background-color: #63A830;
  color: #FFF; 
  margin-bottom: 18pt;
  margin-top: 18pt;
  margin-left: auto;
  margin-right: auto;
  width: 82%;
  height: 36pt;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0pt solid #c3c1c1;
  border-radius: 5pt;
  font-weight: 500;
  font-size: 13pt;
}
.crear_cuenta_login {
  text-decoration: underline;
  font-size: 11pt;
}

.olvidar_contra_login:hover, .crear_cuenta_login:hover, .btn_form_login:hover {
  cursor: pointer;
  opacity: 0.4;
}
 
.parrafo_error_login {
  font-size: 11pt;
  margin: 6pt auto;
}

.div_esconder_error_login {
  display: none; 
}
.div_mostrar_error_login {
  display: block;
  color: red;
  border: 1pt solid;
  border-radius: 5pt;
  margin: 10pt auto;
  width: 82%;
}